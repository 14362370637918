import $ from "jquery";

const removeFieldForm = () => {
  $("form").on("click", ".remove_record", function (event) {
    $(this).prev("input[type=hidden]").val("1");
    $(this).closest("tr").hide();
    return event.preventDefault();
  });

  $(document).on('click', '.remove_record', function(event) {
    event.preventDefault();
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('.location_field, .events_staffs_field').hide();
  });

};

export { removeFieldForm };
